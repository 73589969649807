<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    <div class="m-t1 tableBox">
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="item => item.orderVehicleId" size="small" @change="changePage" :columns="columns" bordered :pagination="pagination" :loading="loading">
        <template #vinNo="{ record }">
          <div>{{ record.vinNo }}</div>
          {{ record.brand }}{{ record.model }}
        </template>
        <template #auditRemark="{ record }">
          {{ record.auditRemark }}
        </template>
        <template #auditStatus="{ record }">
          <span :style="{color:colors[record.auditStatus?.value]}">{{ record.auditStatus?.label }}
            <a-tooltip>
                  <template #title>{{ record.auditRemark || '没有审核备注' }}</template>
                  <MessageOutlined  :style="{ marginLeft: '5px' }" />
                </a-tooltip>
          </span>
        </template>
        <template #add="{ record }">
          <a v-if="record.auditStatus?.value === 0" @click="reviewAdd(record)">审核</a>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="reviewVisible" title="审核" :confirmLoading="btnLoading" @ok="submitAdd" destroyOnClose>
      <FormList  @getVal="getVal"  ref="FormListRef" :fromList="fromList"></FormList>
    </a-modal>
  </div>
</template>

<script setup>
import SearchList from '@/components/searchList'
import FormList from '@/components/formList'
import { analysisPage, analysisAudit } from '@/api/transport/transport'
import { onMounted, ref } from 'vue'
import { cloneDeep } from 'lodash-es'
import { transitionTimeYMD } from '@/utils/util'
import { message } from 'ant-design-vue'
import { MessageOutlined } from '@ant-design/icons-vue'

const pagination = ref({
  showSizeChanger: true,
  showQuickJumper: true,
  current: 1,
  pageSize: 10,
  total: 0,
  showTotal: (totals) => `共 ${totals} 条`
})
const id = ref('')
const FormListRef = ref(null)
const reviewVisible = ref(false)
const loading = ref(false)
const dataSource = ref([])
const searchMsg = ref({})
const btnLoading = ref(false)

let colors = {
  1: '#2A9241',
  2: '#D9001B'
}
const changePage = (e) => {
  pagination.value = e
  getAnalysisPage()
}
//搜索
const searchAdd = (e) => {
  pagination.value.current = 1
  let msg = cloneDeep(e)
  msg.reportTimeStart = transitionTimeYMD(msg.reportTimeStart)
  msg.reportTimeEnd = transitionTimeYMD(msg.reportTimeEnd)
  searchMsg.value = msg
  getAnalysisPage()
}
const resetBtn = () => {
  searchMsg.value = {}
  pagination.value.current = 1
  getAnalysisPage()
}
// 审核
const getVal = (e) => {
  if(e.auditStatus === 2){
    if(!e.auditRemark){
      message.error('请填写备注')
      return
    }
  }
  btnLoading.value = true
  analysisAudit({
    id:id.value,
    ...e
  }).then( res => {
    if(res.code !== 10000) return
    message.success('审核成功')
    reviewVisible.value = false
    getAnalysisPage()
  }).finally(() => {
    btnLoading.value = false
  })
}
const submitAdd = () => {
  FormListRef.value.getValRef()
}
const reviewAdd = (record) => {
  id.value = record.id
  reviewVisible.value = true
}
const getAnalysisPage = () => {
  loading.value = true
  analysisPage({
    ...searchMsg.value,
    current: pagination.value.current,
    size: pagination.value.pageSize
  }).then(res => {
    if (res.code !== 10000) return
    console.log(res);
    dataSource.value = res.data.records
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}
onMounted(() => {
  getAnalysisPage()
})
const fromList = ref([
  {
    label: "审核结果",
    name: "auditStatus",
    type: "select",
    placeholder: "请选择",
    value: null,
    prop: "auditStatus",
    width: "100%",
    opt: [
      {
        name: "通过",
        id: 1
      },
      {
        name: "不通过",
        id: 2
      }
    ],
    rules: [{ required: true, message: "请选择", trigger: "blur", type: "number" }],
  },
  {
    label: "备注",
    name: "auditRemark",
    type: "textarea",
    placeholder: "填写备注",
    value: null,
    prop: "auditRemark",
    width: "100%",
    rows: 3
  },
])
const iptData = ref([
  {
    type: "selectTime",
    placeholder: "提交时间起",
    value: null,
    prop: "reportTimeStart",
    width: 200,
    changeOn: true
  },
  {
    type: "selectTime",
    placeholder: "提交时间止",
    value: null,
    prop: "reportTimeEnd",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "异常原因",
    value: null,
    prop: "exceptionType",
    width: 200,
    opt: [
      {
        name:'客户原因',
        id:1
      },
      {
        name:'同行原因',
        id:2
      },
      {
        name:'自然因素',
        id:3
      },
      {
        name:'个人原因',
        id:4
      },
      {
        name:'公司原因',
        id:5
      },
      {
        name:'车辆异常',
        id:6
      },
      {
        name:'定位不准',
        id:8
      },
      {
        name:'单台凑板',
        id:10
      },
      {
        name:'其他原因',
        id:7
      },
    ],
  },
  {
    type: "select",
    placeholder: "审核状态",
    value: null,
    prop: "auditStatus",
    width: 200,
    opt: [
      {
        name: "待审核",
        id: 0
      },
      {
        name: "审核通过",
        id: 1
      },
      {
        name: "不通过",
        id: 2
      },
    ]
  },

  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "input",
    placeholder: "上报人",
    value: null,
    prop: "reporter",
    width: 200,
  },
])
const columns = ref([
  {
    title: '上报车辆',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
    width: 150
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
    width: 100
  },
  {
    title: '上报人',
    dataIndex: 'reporter',
    align: 'center',
    width: 100
  },
  {
    title: '上报时间',
    dataIndex: 'reportTime',
    align: 'center',
    width: 180
  },
  {
    title: '异常原因',
    dataIndex: 'exceptionType.label',
    align: 'center',
    width: 120
  },
  {
    title: '备注',
    dataIndex: 'remark',
    slots: {
      customRender: 'remark'
    },
    align: 'center',
  },
  {
    title: '审核结果',
    dataIndex: 'auditStatus',
    align: 'center',
    slots: {
      customRender: 'auditStatus'
    },
    width: 150
  },
  {
    title: '审核人',
    dataIndex: 'auditor',
    align: 'center',
    width: 150
  },
  {
    title: '审核时间',
    dataIndex: 'auditTime',
    align: 'center',
    width: 180
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    },
    width: 100
  },

])
</script>

<style lang="less" scoped>
</style>